export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function calculateChecksumDigitEan13(code){
    // calculate check digit
    let sum_a = 0
    for (let i = 1; i < 13 -1; i += 2){
        sum_a += parseInt(code[i]);
    }
    sum_a *= 3
    let sum_b =0
    for (let i = 0; i < 13 - 1; i += 2){
        sum_b += parseInt(code[i]);
    }
    let checksumDigit = (sum_a + sum_b) % 10;
    if (checksumDigit > 0){
        checksumDigit =(10 - checksumDigit);
    }

    return checksumDigit;
}

export function generate_random_EAN (){
    let random_number = "14" + getRandomInt(Math.pow(10, 9), Math.pow(10, 10)-1);
    random_number += calculateChecksumDigitEan13(random_number);

    return random_number;
}
