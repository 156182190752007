import { generate_random_EAN } from './ean';
import { new_location_amount } from './functions';


// Neuen Artikel erstellen
var button = document.querySelector("#new_random_ean")
var textfeld_ean = document.querySelector("[name='new_ean']")
var new_category_button = document.querySelector("#new_category_button")
var new_location_button = document.querySelector("#new_location_button")
var inputfield_new_category = document.querySelector("#new_new_category")
var inputfield_new_location = document.querySelector("#new_new_location")
var category_select = document.querySelector("[name='new_category']")




button.addEventListener("click", function(){
    textfeld_ean.value = generate_random_EAN()
})

new_category_button.addEventListener("click", function(){
    var is_required = category_select.attributes.getNamedItem("required") !== null
    if (is_required){
        category_select.attributes.removeNamedItem("required")
        inputfield_new_category.attributes.setNamedItem(document.createAttribute("required"))
    }
    else {
        category_select.attributes.setNamedItem(document.createAttribute("required"))
        inputfield_new_category.attributes.removeNamedItem("required")
    }


})

new_location_button.addEventListener("click", function() {
    const new_location_amounts = document.querySelectorAll(".popup_location_amount")
    const is_required = new_location_amounts[0].querySelector("select").attributes.getNamedItem("required") !== null
    if (is_required) {
        for (let index in new_location_amounts) {

            if (!new_location_amounts.hasOwnProperty(index)) {
                continue
            }
            new_location_amounts[index].querySelector("select").attributes.removeNamedItem("required")
            new_location_amounts[index].querySelector("input").attributes.removeNamedItem("required")
        }


        inputfield_new_location.querySelector("select").attributes.setNamedItem(document.createAttribute("required"))
        inputfield_new_location.querySelector("input").attributes.setNamedItem(document.createAttribute("required"))

    } else {
        for (let index in new_location_amounts) {

            if (!new_location_amounts.hasOwnProperty(index)) {
                continue
            }
            new_location_amounts[index].querySelector("select").attributes.setNamedItem(document.createAttribute("required"))
            new_location_amounts[index].querySelector("input").attributes.setNamedItem(document.createAttribute("required"))
        }
        inputfield_new_location.querySelector("select").attributes.removeNamedItem("required")
        inputfield_new_location.querySelector("input").attributes.removeNamedItem("required")

    }
})

// header.php Neues Produkt anlegen
new_location_amount('popup_')


