export function new_location_amount (prefix) {


    const button_new_location_amount = document.querySelector("[name='" + prefix + "new_location_amount']")
    const location_amount = document.querySelector("." + prefix + "location_amount")
    const locations_amounts = document.querySelector("." + prefix + "locations_amounts")


    button_new_location_amount.addEventListener("click", function () {
        document.querySelector("[name='" + prefix + "delete_location_amount']").classList.remove('hidden')
        const template_location_amount = location_amount.cloneNode(true)
        template_location_amount.querySelector("select").value = ''
        template_location_amount.querySelector("input").value = ''
        const button_template_location_amount = template_location_amount.querySelector("button")
        button_template_location_amount.addEventListener("click", minus_button_weg_click)
        locations_amounts.append(template_location_amount)
    })


    const buttons_delete_location_amount = document.querySelectorAll("[name='" + prefix + "delete_location_amount']")

    for (let idx in buttons_delete_location_amount) {
        if (!buttons_delete_location_amount.hasOwnProperty(idx)) {
            continue
        }
        const button_delete_location_amount = buttons_delete_location_amount[idx];
        button_delete_location_amount.addEventListener("click", minus_button_weg_click)
    }

    function minus_button_weg_click() {
        const anzahl_location_amount = location_amount.querySelectorAll("." + prefix + "location_amount")
        const min_anzahl_location_amount = anzahl_location_amount.length
        if (min_anzahl_location_amount === 1) {
            return false
        }
        this.parentNode.remove()
        minus_button_weg()
    }


    function minus_button_weg() {
        const new_elements_location_amount = document.querySelectorAll("[name='" + prefix + "delete_location_amount']")
        if (new_elements_location_amount.length === 1) {
            new_elements_location_amount[0].classList.add('hidden');
        }
    }

    minus_button_weg()
}