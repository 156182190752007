//Modals
import * as bootstrap from 'bootstrap';
import 'bootstrap/scss/bootstrap.scss';

import './global.scss';
import './forms';

const inputToggleButtons = document.querySelectorAll('[data-bs-toggle="input"]');
for (let idx in inputToggleButtons) {
    if (!inputToggleButtons.hasOwnProperty(idx)){
        continue
    }

    const inputToggleButton = inputToggleButtons[idx];
    const targetIds = inputToggleButton.dataset.bsTarget;
    const targetId_array = targetIds.split(",")

    for (let idx in targetId_array) {
        const targetEl = document.querySelector(targetId_array[idx]);
        inputToggleButton.addEventListener('click', (ev) => {
            targetEl.classList.toggle('hidden');
        });
    }

}

const report_validity_inputs = document.querySelectorAll('[data-report-validity]');
for (let idx in report_validity_inputs) {
    if (!report_validity_inputs.hasOwnProperty(idx)) {
        continue
    }

    const report_validity_input = report_validity_inputs[idx];
    report_validity_input.addEventListener('change', function (event) {
        if (!event.target.checkValidity()) {
            report_validity_input.reportValidity();
        }
    })
}
